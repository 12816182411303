<template>
  <div class="Crew">
    <div class="crew-section crew1">
      <div class="crew-cont">
        <div class="crew-dic">
          <h5 class="titel"><span>02</span> Meet your crew</h5>
          <h4>Commander</h4>
          <h3>Douglas Hurley</h3>
          <p>
            Douglas Gerald Hurley is an American engineer, former Marine Corps
            pilot and former NASA astronaut. He launched into space for the
            third time as commander of Crew Dragon Demo-2.
          </p>
          <div class="main-dotes">
            <div data-cont=".crew1" class="dote active"></div>
            <div data-cont=".crew2" class="dote"></div>
            <div data-cont=".crew3" class="dote"></div>
            <div data-cont=".crew4" class="dote"></div>
          </div>
        </div>
      </div>
      <div class="crews-img">
        <img src="../assets/Desktop/Crew-1.png" alt="" />
      </div>
    </div>
    <div class="crew-section crew2">
      <div class="crew-cont">
        <div class="crew-dic">
          <h5 class="titel"><span>02</span> Meet your crew</h5>
          <h4>Mission Specialist</h4>
          <h3>MARK SHUTTLEWORTH</h3>
          <p>
            Mark Richard Shuttleworth is the founder and CEO of Canonical, the
            company behind the Linux-based Ubuntu operating system. Shuttleworth
            became the first South African to travel to space as a space
            tourist.
          </p>
          <div class="main-dotes">
            <div data-cont=".crew1" class="dote"></div>
            <div data-cont=".crew2" class="dote active"></div>
            <div data-cont=".crew3" class="dote"></div>
            <div data-cont=".crew4" class="dote"></div>
          </div>
        </div>
      </div>
      <div class="crews-img">
        <img src="../assets/Desktop/Crew-2.png" alt="" />
      </div>
    </div>
    <div class="crew-section crew3">
      <div class="crew-cont">
        <div class="crew-dic">
          <h5 class="titel"><span>02</span> Meet your crew</h5>
          <h4>PILOT</h4>
          <h3>Victor Glover</h3>
          <p>
            Pilot on the first operational flight of the SpaceX Crew Dragon to
            the International Space Station. Glover is a commander in the U.S.
            Navy where he pilots an F/A-18.He was a crew member of Expedition
            64, and served as a station systems flight engineer.
          </p>
          <div class="main-dotes">
            <div data-cont=".crew1" class="dote"></div>
            <div data-cont=".crew2" class="dote"></div>
            <div data-cont=".crew3" class="dote active"></div>
            <div data-cont=".crew4" class="dote"></div>
          </div>
        </div>
      </div>
      <div class="crews-img">
        <img src="../assets/Desktop/Crew-3.png" alt="" />
      </div>
    </div>
    <div class="crew-section crew4">
      <div class="crew-cont">
        <div class="crew-dic">
          <h5 class="titel"><span>02</span> Meet your crew</h5>
          <h4>Flight Engineer</h4>
          <h3>Anousheh Ansari</h3>
          <p>
            Anousheh Ansari is an Iranian American engineer and co-founder of
            Prodea Systems. Ansari was the fourth self-funded space tourist, the
            first self-funded woman to fly to the ISS, and the first Iranian in
            space.
          </p>
          <div class="main-dotes">
            <div data-cont=".crew1" class="dote"></div>
            <div data-cont=".crew2" class="dote"></div>
            <div data-cont=".crew3" class="dote"></div>
            <div data-cont=".crew4" class="dote active"></div>
          </div>
        </div>
      </div>
      <div class="crews-img">
        <img src="../assets/Desktop/Crew-4.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  beforeCreate: function () {
    document.body.className = "crew";
  },
  name: "Crew",
  mounted() {
    let mainDotes = document.querySelectorAll(".main-dotes div");
    let dotesArray = Array.from(mainDotes);

    let sections = document.querySelectorAll(".Crew > div");
    let sectionsArray = Array.from(sections);

    dotesArray.forEach((ele) => {
      ele.addEventListener("click", function (e) {
        sectionsArray.forEach((section) => {
          section.style.display = "none";
        });
        document.querySelector(e.currentTarget.dataset.cont).style.display =
          "flex";
      });
    });
  },
};
</script>

<style lang="scss">
body.crew {
  background: #0c0e19;
  background-image: url("../assets/Desktop/Crew-background.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.crew {
  .Crew > div:not(:first-child) {
    display: none;
  }
  .crew-section {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: absolute;
    bottom: 0px;
    height: 70%;
  }
  .crew-cont {
    display: flex;
    flex-direction: column;
    width: 48%;
    align-items: flex-end;
  }
  .crew-dic {
    width: 80%;
  }
  .titel {
    color: #ffffff;
    margin-bottom: 5em;
    margin-top: 0;
  }
  .titel span {
    padding-right: 8px;
    opacity: 0.25;
  }
  h4 {
    opacity: 0.25;
    margin-bottom: 0.5em;
  }
  p {
    width: 23em;
  }
  .main-dotes {
    display: flex;
    flex-direction: row;
    margin-top: 5em;
  }
  .dote {
    height: 15px;
    width: 15px;
    background: #ffffff;
    border-radius: 50px;
    opacity: 0.17;
    margin-right: 1.4em;
    cursor: pointer;
  }
  .dote:hover {
    opacity: unset;
    background: #d0d6f9;
  }
  .active {
    opacity: unset;
  }
  .crews-img {
    width: 52%;
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 1200px) {
  body.crew {
    background-image: url("../assets/Tablet/Crew-Background.png");
  }
  .crew {
    .crew-section {
      flex-direction: column;
      align-items: center;
    }
    .crew-cont {
      width: 100%;
      align-items: center;
      text-align: center;
      margin-bottom: 2em;
    }
    .main-dotes {
      margin-top: 3em;
      justify-content: center;
      .dote {
        margin: 0 15px;
      }
    }
    .titel {
      margin-bottom: unset;
      position: absolute;
      left: 2em;
      top: -2.6em;
    }
    p {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-width: 440px;
    }
    .crews-img {
      width: 100%;
      justify-content: center;
      img {
        width: 410px;
        height: 410px;
      }
    }
  }
}
@media (max-width: 600px) {
  body.crew {
    background-image: url("../assets/Mobile/Crew-background.png");
  }
  .crew {
    .crew-section {
      flex-direction: column-reverse;
      position: relative;
      top: 6em;
      bottom: unset;
    }
    .titel {
      text-transform: uppercase;
      position: relative;
      left: unset;
      top: -17em;
    }
    .crews-img img {
      width: 200px;
      height: 220px;
    }
    .crew-cont {
      flex-direction: column-reverse;
      width: 80%;
      margin-bottom: unset;
    }
    .main-dotes {
      margin-top: 2em;
      margin-bottom: 1em;

      .dote {
        margin: 0px 8px;
        height: 10px;
        width: 10px;
      }
    }
  }
}
</style>
