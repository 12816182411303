<template>
  <div class="Header">
    <img src="../../assets/Group 2.svg" alt="" />
    <hr />
    <div class="nav">
      <router-link to="/"><span>00</span>Home</router-link>
      <router-link to="/destination"> <span>01</span>Destination</router-link>
      <router-link to="/crew"> <span>02</span>Crew</router-link>
      <router-link to="/technology"> <span>03</span>Technology </router-link>
    </div>
    <div id="humb" class="humbMenu">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppHeader",
  mounted() {
    let humbButton = document.getElementById("humb");
    let menus = document.querySelector(".nav");

    humbButton.addEventListener("click", function () {
      if (menus.classList == "nav") {
        menus.classList.add("nav-open");
        humbButton.classList.add("humbMenu-open");
      } else {
        menus.classList.add("nav");
        menus.classList.remove("nav-open");
        humbButton.classList.remove("humbMenu-open");
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 96px;
  position: relative;
  top: 40px;
  img {
    border-style: none;
    position: relative;
    left: 55px;
  }
  hr {
    background: #ffffff;
    mix-blend-mode: normal;
    opacity: 0.25;
    width: 34%;
    height: 1px;
    position: relative;
    left: 90px;
    z-index: 1;
  }
  .nav {
    text-decoration: none;
    list-style: none;
    display: flex;
    flex-direction: row;
    background: rgba(255, 255, 255, 0.04);
    backdrop-filter: blur(20px);
    align-items: center;
    margin: 0;
    padding: 0;
    padding-left: 4%;
    width: 50%;
    height: 100%;
    a {
      text-decoration: none;
      text-transform: uppercase;
      color: #ffffff;
      padding: 38px 2px 35px;
      margin: 0px 0px 0 3.4em;
      font-size: 16px;
      letter-spacing: 2.7px;
      span {
        font-weight: bold;
        margin-right: 4px;
      }
      &.router-link-exact-active {
        border-bottom: white solid;
      }
    }
  }
}

.humbMenu {
  display: none;
}
@media (max-width: 1200px) {
  .Header {
    position: unset;
    top: 0;
    img {
      left: 30px;
    }
    hr {
      display: none;
    }
    .nav {
      padding-right: 45px;
      padding-left: 7px;
      width: unset;
      a {
        margin: 0px 0px 0 2.4em;
        span {
          font-weight: bold;
          margin-right: 4px;
          display: none;
        }
      }
    }
    .humbMenu {
      display: none;
    }
  }
}
@media (max-width: 600px) {
  .Header {
    display: unset;
    position: unset;
    top: 0;
    img {
      height: 40px;
      width: 40px;
      left: 20px;
      top: 20px;
    }
    hr {
      display: none;
    }
    .nav {
      display: none;
    }
    .nav-open {
      display: flex;
      flex-direction: column;
      position: fixed;
      top: 0;
      right: 0;
      z-index: 2;
      height: 100%;
      width: 250px;
      align-items: flex-start;
      background: rgba(255, 255, 255, 0.04);
      backdrop-filter: blur(41.5485px);
      padding-top: 5em;
      a {
        text-decoration: none;
        text-transform: uppercase;
        color: #ffffff;
        padding: 20px 2px 20px;
        margin: 0px 0px 0 3.4em;
        font-size: 16px;
        letter-spacing: 2.7px;
        span {
          display: inline-block;
          font-weight: bold;
          margin-right: 4px;
        }
        &.router-link-exact-active {
          border-bottom: white solid;
        }
      }
    }
    .humbMenu {
      display: block;
      position: fixed;
      right: 25px;
      top: 25px;
      z-index: 2;
      cursor: pointer;
      div {
        width: 24px;
        height: 3px;
        background-color: #d0d6f9;
        margin: 5px 0;
      }
    }
    .humbMenu-open {
      display: block;
      position: fixed;
      right: 27px;
      top: 36px;
      z-index: 2;
      cursor: pointer;
      :first-child {
        transform: rotate(-45deg);
      }
      :nth-child(2) {
        display: none;
      }
      :last-child {
        transform: rotate(45deg);
        margin-top: -8px;
      }
      div {
        width: 24px;
        height: 3px;
        background-color: #d0d6f9;
        margin: 5px 0;
      }
    }
  }
}
</style>
