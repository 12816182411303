<template>
  <div class="Destination">
    <h5 class="titel"><span>01</span> Pick your destination</h5>
    <div class="display-con">
      <div class="destination-cont moon">
        <div class="moon-img">
          <img src="../assets/Desktop/Moon.svg" alt="moon" />
        </div>
        <div class="moon-container">
          <div class="moon-dic">
            <ul class="nav-text">
              <li><a class="active" data-cont=".moon">moon</a></li>
              <li><a data-cont=".mars">mars</a></li>
              <li><a data-cont=".europa">europa</a></li>
              <li><a data-cont=".titan">titan</a></li>
            </ul>
            <h2>MOON</h2>
            <p>
              See our planet as you’ve never seen it before. A perfect relaxing
              trip away to help regain perspective and come back refreshed.
              While you’re there, take in some history by visiting the Luna 2
              and Apollo 11 landing sites.
            </p>
            <hr />
            <div class="moon-info-container">
              <div class="moon-info1">
                <!-- <div class="moon-info1-dic"> -->
                <a class="sub2"> AVG. DISTANCE</a>
                <a class="sub1"> 384,400 kM</a>
                <!-- </div> -->
              </div>
              <div class="moon-info2">
                <!-- <div class="moon-info2-dic"> -->
                <a class="sub2"> Est. travel time</a>
                <a class="sub1"> 3 days</a>
                <!-- </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="destination-cont mars">
        <div class="moon-img">
          <img src="../assets/Desktop/Mars.svg" alt="mars" />
        </div>
        <div class="moon-container">
          <div class="moon-dic">
            <ul class="nav-text">
              <li><a data-cont=".moon">moon</a></li>
              <li><a class="active" data-cont=".mars">mars</a></li>
              <li><a data-cont=".europa">europa</a></li>
              <li><a data-cont=".titan">titan</a></li>
            </ul>
            <h2>MARS</h2>
            <p>
              Don’t forget to pack your hiking boots. You’ll need them to tackle
              Olympus Mons, the tallest planetary mountain in our solar system.
              It’s two and a half times the size of Everest!
            </p>
            <hr />
            <div class="moon-info-container">
              <div class="moon-info1">
                <!-- <div class="moon-info1-dic"> -->
                <a class="sub2"> AVG. DISTANCE</a>
                <a class="sub1"> 225 MIL. km</a>
                <!-- </div> -->
              </div>
              <div class="moon-info2">
                <!-- <div class="moon-info2-dic"> -->
                <a class="sub2"> Est. travel time</a>
                <a class="sub1"> 9 months</a>
                <!-- </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="destination-cont europa">
        <div class="moon-img">
          <img src="../assets/Desktop/Europa.svg" alt="europa" />
        </div>
        <div class="moon-container">
          <div class="moon-dic">
            <ul class="nav-text">
              <li><a data-cont=".moon">moon</a></li>
              <li><a data-cont=".mars">mars</a></li>
              <li><a class="active" data-cont=".europa">europa</a></li>
              <li><a data-cont=".titan">titan</a></li>
            </ul>
            <h2>EUROPA</h2>
            <p>
              The smallest of the four Galilean moons orbiting Jupiter, Europa
              is a winter lover’s dream. With an icy surface, it’s perfect for a
              bit of ice skating, curling, hockey, or simple relaxation in your
              snug wintery cabin.
            </p>
            <hr />
            <div class="moon-info-container">
              <div class="moon-info1">
                <!-- <div class="moon-info1-dic"> -->
                <a class="sub2"> AVG. DISTANCE</a>
                <a class="sub1"> 628 MIL. km</a>
                <!-- </div> -->
              </div>
              <div class="moon-info2">
                <!-- <div class="moon-info2-dic"> -->
                <a class="sub2"> Est. travel time</a>
                <a class="sub1"> 3 years</a>
                <!-- </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="destination-cont titan">
        <div class="moon-img">
          <img src="../assets/Desktop/Titan.svg" alt="titan" />
        </div>
        <div class="moon-container">
          <div class="moon-dic">
            <ul class="nav-text">
              <li><a data-cont=".moon">moon</a></li>
              <li><a data-cont=".mars">mars</a></li>
              <li><a data-cont=".europa">europa</a></li>
              <li><a class="active" data-cont=".titan">titan</a></li>
            </ul>
            <h2>TITAN</h2>
            <p>
              The only moon known to have a dense atmosphere other than Earth,
              Titan is a home away from home (just a few hundred degrees
              colder!). As a bonus, you get striking views of the Rings of
              Saturn.
            </p>
            <hr />
            <div class="moon-info-container">
              <div class="moon-info1">
                <!-- <div class="moon-info1-dic"> -->
                <a class="sub2"> AVG. DISTANCE</a>
                <a class="sub1"> 1.6 BIL. km</a>
                <!-- </div> -->
              </div>
              <div class="moon-info2">
                <!-- <div class="moon-info2-dic"> -->
                <a class="sub2"> Est. travel time</a>
                <a class="sub1"> 7 years</a>
                <!-- </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      pageName: "Destination",
      discribtion: "This is an Destination page",
    };
  },
  beforeCreate: function () {
    document.body.className = "destination";
  },
  name: "Destination",

  mounted() {
    let navText = document.querySelectorAll(".nav-text li a");
    let navArray = Array.from(navText);
    let divs = document.querySelectorAll(".display-con > div");
    let divsArray = Array.from(divs);

    navArray.forEach((ele) => {
      ele.addEventListener("click", function (e) {
        divsArray.forEach((div) => {
          div.style.display = "none";
        });
        document.querySelector(e.currentTarget.dataset.cont).style.display =
          "flex";
      });
    });
  },
};
</script>

<style lang="scss">
body.destination {
  background: #0e101b;
  background-image: url(../assets/Desktop/Destination-Background.png);
  background-size: 100vw 100vh;
  background-repeat: no-repeat;
}
.destination {
  .Destination {
    position: absolute;
    top: 10em;
    width: 100%;
  }
  .display-con > :not(:first-child) {
    display: none;
  }
  .titel {
    position: relative;
    left: 6em;
    color: #ffffff;
    span {
      padding-right: 8px;
      opacity: 0.25;
    }
  }

  .destination-cont {
    display: flex;
    width: 100%;
    position: relative;
    top: 4em;
  }
  .moon-img {
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
    img {
      animation: imagrotate linear 8s infinite;
    }
  }
  @keyframes imagrotate {
    100% {
      transform: rotate(360deg);
    }
  }
  .moon-container {
    width: 50%;
    display: flex;
    justify-content: center;
    .moon-dic {
      width: 26em;
    }
    hr {
      border: 0;
      height: 1px;
      background: #333;
      margin: 30px 0 10px;
    }
  }

  .Destination ul {
    display: flex;
    justify-content: flex-start;
    padding: 0;
    text-decoration: none;
    list-style: none;
    text-transform: uppercase;
    li {
      width: 85px;
      a {
        cursor: pointer;
        padding-bottom: 10px;
      }
      a:hover {
        color: #ffffff;
      }
      .active {
        color: #ffffff;
        border-bottom: solid #ffffff 2px;
        padding-bottom: 10px;
      }
      .active:hover {
        color: #d0d6f9;
        border-bottom: solid #d0d6f9 2px;
      }
    }
  }

  .moon-info-container {
    display: flex;
    width: 100%;
    text-transform: uppercase;
    padding-top: 10px;
    .moon-info1 {
      display: flex;
      flex-direction: column;
      width: 50%;
    }
    .moon-info2 {
      display: flex;
      flex-direction: column;
      width: 50%;
    }
  }
}
@media (max-width: 1200px) {
  body.destination {
    background-image: url("../assets/Tablet/Destination-Background.png");
  }
  .destination {
    .Destination {
      top: 7em;
    }
    .titel {
      left: 2em;
    }
    .destination-cont {
      // display: flex;
      // width: 100%;
      // position: relative;
      top: 2em;
      flex-direction: column;
      align-items: center;
    }
    .moon-img {
      display: flex;
      width: 90%;
      margin-bottom: 2em;
      img {
        width: 300px;
        height: 300px;
      }
    }
    .Destination ul {
      justify-content: center;
    }
    .moon-container {
      width: 100%;
      text-align: center;
      .moon-dic {
        width: 75%;
      }
    }
    p {
      width: 95%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
@media (max-width: 600px) {
  body .destination {
    background-image: url("../assets/Mobile/Destination-Background.png");
  }
  .destination {
    .Destination {
      top: 5em;
      text-align: center;
    }
    .destination-cont {
      top: 1em;
    }
    .titel {
      position: unset;
      text-transform: uppercase;
    }
    .moon-img {
      margin-bottom: 1em;
      img {
        width: 170px;
        height: 170px;
      }
    }
    .nav-text {
      font-size: 14px;
    }
    .moon-info-container {
      display: flex;
      width: 100%;
      text-transform: uppercase;
      padding-top: 10px;
      flex-direction: column;
      align-items: center;
      .moon-info1 {
        margin-bottom: 25px;
      }
      .moon-info2 {
        margin-bottom: 25px;
      }
    }
    .moon-container .moon-dic {
      width: 85%;
    }
    hr {
      border: 0;
      margin: 20px 0 20px;
    }
  }
}
</style>
