<template>
  <div class="home">
    <section class="home-container">
      <div class="space-dic">
        <div class="space-container">
          <h5>SO, YOU WANT TO TRAVEL TO</h5>
          <h1>SPACE</h1>
          <p>
            Let’s face it; if you want to go to space, you might as well
            genuinely go to outer space and not hover kind of on the edge of it.
            Well sit back, and relax because we’ll give you a truly out of this
            world experience!
          </p>
        </div>
      </div>
      <div class="land-button"><a> explore </a></div>
    </section>
  </div>
</template>

<script>
export default {
  beforeCreate: function () {
    document.body.className = "home";
  },
  name: "Home",
};
</script>

<style lang="scss">
body.home {
  background: #0b0d17;
  background-image: url(../assets/Desktop/Earth.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}
.home {
  .home-container {
    display: flex;
    width: 100%;
    position: relative;
    bottom: -14em;
  }
  .space-dic {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
  }
  .space-container {
    width: 440px;
  }
  .land-button {
    display: flex;
    width: 50%;
    height: 300px;
    align-items: center;
    justify-content: center;
  }
  .land-button a {
    background: white;
    position: relative;
    top: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 275px;
    height: 275px;
    border-radius: 50%;
    /* border: #262626ba solid 30px; */
    text-decoration: none;
    color: black;
    font-size: 32px;
    text-transform: uppercase;
    font-family: "Bellefair", serif;
  }
  .land-button a:hover {
    border: #262626ba solid 30px;
  }
}
@media (min-width: 500px) and (max-width: 1200px) {
  body.home {
    background-image: url("../assets/Tablet/Home-Background.jpg");
  }
  .home {
    .home-container {
      flex-direction: column;
      align-items: center;
      text-align: center;
      position: relative;
      bottom: -4em;
    }
    .land-button a {
      width: 242px;
      height: 242px;
    }
  }
}
@media (max-width: 500px) {
  body.home {
    background-image: url("../assets/Mobile/Home-Background.jpg");
  }
  .home {
    .home-container {
      display: flex;
      width: 100%;
      position: relative;
      bottom: -3em;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    .space-dic {
      width: 90%;
    }
    .space-container {
      width: 100%;
    }
    .land-button {
      width: 100%;
      height: 225px;
    }
    .home .land-button a {
      position: unset;
      width: 150px;
      height: 150px;
      border-radius: 50%;
      font-size: 20px;
      line-height: 23px;
    }
    p {
      width: 95%;
      margin: auto;
    }
  }
}
</style>
