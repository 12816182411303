<template>
  <div id="app">
    <app-header></app-header>

    <router-view></router-view>

    <!-- <app-footer></app-footer> -->
  </div>
</template>

<script>
import AppHeader from "./components/global/AppHeader.vue";
// import AppFooter from "./components/global/AppFooter.vue";
//  "app-footer": AppFooter
export default {
  components: { "app-header": AppHeader },
  name: "App",
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed&family=Bellefair&display=swap");
#app {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 16px;
  color: #ffffff;
}

a {
  &.router-link-exact-active {
    border-bottom: white solid;
  }
}
h1,
h2,
h3,
h4,
.sub1 {
  font-family: "Bellefair", serif;
  font-style: normal;
  font-weight: normal;
  text-transform: uppercase;
  margin: 0;
}
h1 {
  font-size: 150px;
  line-height: 172px;
}
h2 {
  font-size: 100px;
  line-height: 115px;
}
h3 {
  font-size: 56px;
}
h4 {
  font-size: 32px;
}
.sub1 {
  font-size: 28px;
  line-height: 32px;
}

h5,
.sub2,
p,
.nav-text {
  font-family: "Barlow Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #d0d6f9;
}
h5 {
  display: inline-block;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 4.725px;
  margin: 18px 0;
}
.sub2 {
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 2.3px;
  margin-bottom: 5px;
}
p {
  font-size: 18px;
  line-height: 31px;
  letter-spacing: 1.1px;
  width: 415px;
}
.nav-text {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 2.7px;
}
@media (min-width: 600px) and (max-width: 1200px) {
  h2 {
    font-size: 80px;
    line-height: 92px;
  }
  h3 {
    line-height: 46px;
    font-size: 40px;
  }
  h5 {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 3.375px;
  }
  p {
    font-size: 16px;
    line-height: 28px;
  }
}
@media (max-width: 600px) {
  h1 {
    font-size: 80px;
    line-height: 100px;
  }
  h2 {
    font-size: 56px;
    line-height: 64px;
  }
  h3 {
    font-size: 24px;
    line-height: 28px;
  }
  h4 {
    font-size: 16px;
    line-height: 18px;
  }
  h5 {
    font-size: 16px;
    line-height: 19px;
  }
  p {
    font-size: 15px;
    line-height: 25px;
  }
}
</style>
